import React from 'react';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import Link from '@/components/Link/Link';
import accordeonImage from '@/public/of-background.webp';
import { backgroundImageMixin } from '@/styles/BackgroundimageMixin';
import { IRoutePath } from '@/types/router';

const FeaturesAccordeon = ({ title }) => {
  return (
    <AccordeonWrapper>
      <p>{title}</p>
    </AccordeonWrapper>
  );
};

export const CardModule = ({ item, isMainPage }) => {
  const items = isMainPage ? item.items.slice(0, 5) : item.items;

  return (
    <Wrapper $ismainpage={isMainPage}>
      <Heading>
        <Title>
          <p>{item.title}</p>
          {!!item?.label && (
            <Label style={{ backgroundColor: item.label.color }}>
              {item.label.title}
            </Label>
          )}
        </Title>
        <Price>{item.price}</Price>
        {!isMainPage && (
          <Button
            onClick={() =>
              window.open('https://app.orcatec.com/auth/signup', 'e_blank')
            }
            style={{ width: '100%' }}
            className="secondary"
          >
            {item.btnTitle}
          </Button>
        )}
      </Heading>
      <Body>
        <HeadListItems>
          {item.items_head.map((item, idx) => (
            <HeadListItem $isfirst={idx === 0} key={idx}>
              <p className="head-icon">{<item.icon />}</p>
              <p className="head-title-wrap">
                <span className="head-title">{item.title}</span>

                <span className="head-title-description">
                  {item?.subTitle || ''}
                </span>
              </p>
            </HeadListItem>
          ))}
        </HeadListItems>
        <ListItems
          style={{
            minHeight: item.title === 'Free' && isMainPage ? '233px' : 'auto',
          }}
        >
          {items.map((item, idx) => (
            <ListItem key={idx}>
              <p>{<item.icon />}</p>
              <p>{item.title}</p>
            </ListItem>
          ))}
        </ListItems>
        {isMainPage && (
          <>
            <LayoutOveray></LayoutOveray>
            <LayoutBlock>
              <Button
                // onClick={() =>
                //   window.open('https://app.orcatec.com/auth/signup', 'e_blank')
                // }
                fullWidth
                className="secondary"
              >
                <Link style={{ display: 'block' }} href={IRoutePath.PRICING}>
                  View more
                </Link>
              </Button>
            </LayoutBlock>
          </>
        )}
      </Body>
      {item.options && !isMainPage && (
        <FeaturesAccordeon title={item.options} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.article<{ $ismainpage: boolean }>`
  position: relative;
  border-radius: 10px;
  border: 1px solid #dbe0ef;
  height: ${(props) => (props.$ismainpage ? '590px' : '100%')};
  width: 100%;
  /* overflow: hidden; */
`;

const Heading = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(219, 224, 239, 1);
`;

const Body = styled.div`
  padding: 24px;
  row-gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Title = styled.h3`
  color: rgba(32, 111, 237, 1);

  font-family: var(--roboto-font);
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Price = styled.h2`
  color: #262626;
  font-family: var(--roboto-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  align-self: stretch;
`;
const HeadListItems = styled.div`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 16px;
`;

const HeadListItem = styled.div<{ $isfirst: boolean }>`
  display: flex;
  align-items: flex-start;
  align-items: start;
  gap: 10px;
  & .head-title-wrap {
    display: flex;
    align-items: ${(props) => (!props.$isfirst ? 'center' : 'start')};
    row-gap: 4px;
    gap: 2px;
    flex-direction: ${(props) => (!props.$isfirst ? 'row' : 'column')};
  }
  & .head-title {
    color: rgba(38, 38, 38, 1);
    font-family: var(--roboto-font);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  & .head-title-description {
    font-family: var(--roboto-font);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(77, 77, 77, 1);
  }
`;

const ListItems = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 12px;
  gap: 10px;
  align-self: stretch;
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  color: #192d52;
  font-family: var(--roboto-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-wrap: balance;
`;

const Label = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: var(--whiteColor);
  text-align: center;
  font-family: var(--roboto-font);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
`;

const AccordeonWrapper = styled.div`
  padding: 16px 24px;
  border-radius: 0 0 6px 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${backgroundImageMixin(accordeonImage.src)};

  background-position: unset;
  background-repeat: repeat;
  & p {
    font-family: var(--roboto-font);
    color: var(--whiteColor);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
`;

const LayoutBlock = styled.div`
  width: 100%;
  z-index: 1;
  & a {
    font-family: var(--roboto-font);
    color: var(--whiteColor);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
  }
`;

const LayoutOveray = styled.div`
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 67%);
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
`;

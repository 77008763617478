import React, { useRef } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CardModule } from '../Pricing/Module/components/CardModule';

import 'swiper/css';
import 'swiper/css/pagination';
import Button from '@/components/Button/Button';
import { useNewsletterModalContext } from '@/context/newsletter-modal.context';
import ultimateImg from '@/public/pricing/ultimate.webp';
import ultimateImg2 from '@/public/pricing/ultimate2.webp';
import { devices } from '@/styles/breakpoints';

export const MainPricingBlock = ({ items }) => {
  const sliderRef = useRef(null);
  const { setIsModalOpened } = useNewsletterModalContext();

  return (
    <Wrapper>
      <CardsWrapper>
        <Swiper
          ref={sliderRef}
          slidesPerView={1.3}
          pagination={true}
          spaceBetween={24}
          navigation={true}
          effect="fade"
          loop={true}
          modules={[Autoplay, Navigation, Pagination]}
          className="pricing-swiper"
          breakpoints={{
            640: {
              slidesPerView: 1.3,
            },
            768: {
              slidesPerView: 1.3,
            },
            1024: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 4,
            },
          }}
        >
          {items.map((item, idx) => (
            <SwiperSlide key={idx}>
              <CardModule
                item={item}
                onOpenModal={() => setIsModalOpened(true)}
                isMainPage={true}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </CardsWrapper>
      <UltimateCard>
        <UltimateCardImage>
          <MobContainer>
            <Image src={ultimateImg} alt="ultimate image" />
          </MobContainer>
          <DescContainer>
            <Image src={ultimateImg2} alt="ultimate image" />
          </DescContainer>
        </UltimateCardImage>
        <UltimateCardContent>
          <p className="card-name">Ultimate</p>
          <div className="card-name-wrapper">
            <p className="name">Enterprise</p>
            <p className="name-tag">Personal</p>
          </div>
          <p className="card-description">
            We know every business is unique and want our system to fit your
            needs perfectly. Tailor every aspect to your preferences by choosing
            the functionalities that matter most to you and consider taking
            advantage of our custom development options for a solution designed
            exclusively for you.
          </p>
          <UltimateCardButton>
            <MobContainer>
              <Button
                onClick={() => setIsModalOpened(true)}
                className="bluer"
                fullWidth
              >
                {' '}
                Contact us
              </Button>{' '}
            </MobContainer>
            <DescContainer>
              <Button
                onClick={() => setIsModalOpened(true)}
                className="secondary"
                fullWidth
              >
                {' '}
                Contact us
              </Button>{' '}
            </DescContainer>
          </UltimateCardButton>
        </UltimateCardContent>
      </UltimateCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .pricing-swiper {
    padding-bottom: 40px;
    @media only screen and ${devices.desktop} {
      padding-bottom: 0px;
    }
  }
  & .swiper-pagination {
    bottom: -4px;
  }
`;

const CardsWrapper = styled.div`
  margin-bottom: 30px;
  @media only screen and ${devices.desktop} {
    margin-bottom: 24px;
  }
`;

const UltimateCard = styled.div`
  @media only screen and ${devices.desktop} {
    display: flex;
  }
`;
const UltimateCardImage = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
  @media only screen and ${devices.desktop} {
    max-width: 270px;
    img {
      height: auto;
      border-radius: 10px 0 0 10px;
    }
  }
`;
const UltimateCardContent = styled.div`
  padding: 16px;

  & .card-name {
    font-family: var(--font-roboto);
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: rgba(32, 111, 237, 1);
    margin-bottom: 8px;
  }
  & .card-name-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
  & .name {
    font-family: var(--font-roboto);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: rgba(25, 45, 82, 1);
  }
  & .name-tag {
    padding: 4px 8px;
    background: rgba(26, 178, 226, 1);
    color: white;
    font-family: var(--font-roboto);
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    border-radius: 4px;
  }
  & .card-description {
    font-family: var(--font-roboto);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(77, 77, 77, 1);
    margin-bottom: 24px;
    @media only screen and ${devices.desktop} {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media only screen and ${devices.desktop} {
    padding: 24px;
    border: 1px solid rgba(237, 242, 250, 1);
    border-radius: 0 10px 10px 0;
    max-height: 245px;
    background: rgba(255, 255, 255, 1);
  }
`;

const UltimateCardButton = styled.div`
  @media only screen and ${devices.desktop} {
    max-width: 140px;
  }
`;

const MobContainer = styled.div`
  @media only screen and ${devices.desktop} {
    display: none;
  }
`;
const DescContainer = styled.div`
  display: none;
  @media only screen and ${devices.desktop} {
    display: block;
  }
`;

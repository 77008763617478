import {
  CheckRingIcon,
  CloudIcon,
  EmailIcon,
  HeadPhoneIcon,
  HeadphoneIcon,
  PhoneIcon,
  UserIcon,
} from '@/public/icons';

const module1 = {
  title: 'Free',
  price: '$0 per month',
  btnTitle: 'Select',
  label: {
    title: 'Default',
    color: ' rgba(0, 166, 67, 1)',
  },
  items_head: [
    {
      title: '1 Gb cloud storage',
      subTitle: '(extra $0.99 Gb)',
      icon: CloudIcon,
    },
    { title: '5 users', subTitle: '', icon: UserIcon },
  ],
  items: [
    { title: 'Dispatch Management (50 per month)', icon: CheckRingIcon },
    { title: 'Projects and estimates (50 per month)', icon: CheckRingIcon },
    { title: 'Schedule', icon: CheckRingIcon },
    { title: 'Properties', icon: CheckRingIcon },
    { title: 'Customer Database', icon: CheckRingIcon },
    { title: 'Job Management', icon: CheckRingIcon },
  ],
};

const module2 = {
  title: 'Basic',
  price: '$19 per month',
  btnTitle: 'Select',
  label: null,
  items_head: [
    {
      title: '3 Gb cloud storage',
      subTitle: '(extra $0.99 Gb)',
      icon: CloudIcon,
    },
    { title: '5 users', subTitle: '(extra users - $10)', icon: UserIcon },
    { title: 'Live support', subTitle: '', icon: HeadphoneIcon },
  ],
  items: [
    { title: 'Dispatch Management', icon: CheckRingIcon },
    { title: 'Projects and estimates', icon: CheckRingIcon },
    { title: 'Schedule', icon: CheckRingIcon },
    { title: 'Properties', icon: CheckRingIcon },
    { title: 'Customer Database', icon: CheckRingIcon },
    { title: 'Job Management', icon: CheckRingIcon },
    { title: 'Map', icon: CheckRingIcon },
    { title: 'Dashboard Management', icon: CheckRingIcon },
    { title: 'Instant Appointment', icon: CheckRingIcon },
    { title: 'Fast Payment', icon: CheckRingIcon },
  ],
};
const module3 = {
  title: 'Basic +',
  price: '$49 per month',
  btnTitle: 'Select',
  label: null,
  items_head: [
    {
      title: '6 Gb cloud storage',
      subTitle: '(extra $0.99 Gb)',
      icon: CloudIcon,
    },
    { title: '5 users', subTitle: '(extra users - $15)', icon: UserIcon },
    { title: 'Live support', subTitle: '', icon: HeadphoneIcon },
  ],
  items: [
    { title: 'Dispatch Management ', icon: CheckRingIcon },
    { title: 'Projects and estimates', icon: CheckRingIcon },
    { title: 'Schedule', icon: CheckRingIcon },
    { title: 'Properties', icon: CheckRingIcon },
    { title: 'Customer Database', icon: CheckRingIcon },
    { title: 'Job Management ', icon: CheckRingIcon },
    { title: 'Map', icon: CheckRingIcon },
    { title: 'Dashboard Management ', icon: CheckRingIcon },
    { title: 'Instant Appointment', icon: CheckRingIcon },
    { title: 'Fast Payment', icon: CheckRingIcon },
  ],
  options: '3 optional features to choose',
};
const module4 = {
  title: 'Pro',
  price: '$149 per month',
  btnTitle: 'Select',
  label: {
    title: 'Popular',
    color: 'rgba(26, 178, 226, 1)',
  },
  items_head: [
    {
      title: '30 Gb cloud storage',
      subTitle: '(extra $0.99 Gb)',
      icon: CloudIcon,
    },
    { title: '10 users ', subTitle: '(extra users - $20)', icon: UserIcon },
    { title: 'Live support', subTitle: '', icon: HeadphoneIcon },
  ],
  items: [
    { title: 'Dispatch Management ', icon: CheckRingIcon },
    { title: 'Projects and estimates', icon: CheckRingIcon },
    { title: 'Schedule', icon: CheckRingIcon },
    { title: 'Properties', icon: CheckRingIcon },
    { title: 'Customer Database', icon: CheckRingIcon },
    { title: 'Job Management ', icon: CheckRingIcon },
    { title: 'Map', icon: CheckRingIcon },
    { title: 'Dashboard Management ', icon: CheckRingIcon },
    { title: 'Instant Appointment', icon: CheckRingIcon },
    { title: 'Fast Payment', icon: CheckRingIcon },
  ],
  options: '6 optional features to choose',
};

const submoduleBlock = {
  title: 'Call Tracking & Messaging',
  subTitle: 'Pricing for all plans',
  items: [
    {
      tag: 'Call Tracking',
      tagPrice: '$0.0299 - per minute',
      title: 'Service fee',
      titlePrice: '$10 ',
      icon: HeadPhoneIcon,
    },
    {
      tag: 'Messaging',
      tagPrice: '$0.03 - per message',
      title: 'Service fee',
      titlePrice: '$10',
      icon: EmailIcon,
    },
    {
      tag: 'Phone number',
      tagPrice: '$10',
      title: 'Additional number',
      titlePrice: '$2.5',
      icon: PhoneIcon,
    },
  ],
};

const contactBlock = {
  title: 'For Enterprise solutions contact us',
  subTitle:
    'Customize the entire system for yourself. Select the necessary functionality. Custom development is available.',
  btnTitle: 'Contact us',
};

export { module1, module2, module3, module4, contactBlock, submoduleBlock };
